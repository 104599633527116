/* eslint-disable jsx-a11y/media-has-caption */
import { Rate } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSupervisors } from 'redux-store/myProviders/myProvidersActions';

export default function About() {
  const dispatch = useDispatch();
  const { selectedMyProviders, supervisors } = useSelector((state) => state.myProviders);
  const [superviseeList, setSuperviseeList] = useState([]);

  useEffect(() => {
    if (selectedMyProviders?.id) {
      let payload = { 'where[supervisees.providerId][equals]': selectedMyProviders?.id };
      if (selectedMyProviders?.providerType?.canSupervise) {
        payload = { 'where[supervisorId][equals]': selectedMyProviders?.id };
      }
      dispatch(getSupervisors(payload));
    }
  }, [selectedMyProviders?.providerType]);

  useEffect(() => {
    if (supervisors?.docs?.length > 0) {
      let items = [];
      if (selectedMyProviders?.providerType?.canSupervise) {
        items = supervisors?.docs[0]?.supervisees?.map((item) => ({
          id: item.providerId.id,
          name: `${item.providerId.fullName}, ${item.providerId.providerType?.providerTypeName}`,
        }));
      } else {
        items = [{
          id: supervisors?.docs[0]?.supervisorId?.id,
          name: `${supervisors?.docs[0]?.supervisorId.fullName}, ${supervisors?.docs[0]?.supervisorId?.providerType?.providerTypeName}`,
        }];
      }
      setSuperviseeList(items);
    }
  }, [supervisors]);

  return (
    <div className="my-provider-container__body--about">
      <div className="about-area">
        <h4>About</h4>
        <p>{_.get(selectedMyProviders, 'biography.selfDescription', '')}</p>
        {_.get(selectedMyProviders, 'biography.providerVideo.url', '') && (
          <video width="400" controls>
            <source
              src={_.get(
                selectedMyProviders,
                'biography.providerVideo.url',
                '',
              )}
              type="video/mp4"
            />
          </video>
        )}
      </div>
      <div className="supervisor-area">
        <h4>Supervision</h4>
        <div className="reviews-rating-wrapper">
          <div className="section_1">
            <div className="wrapper">
              <span className="label">Supervisior</span>
              <span className="value">
                {selectedMyProviders?.providerType?.canSupervise ? 'Yes' : 'No'}
                {selectedMyProviders?.providerType?.canSupervise && <TickSign />}
              </span>
            </div>
          </div>
          {superviseeList && superviseeList?.length > 0 && (
            <div className="section_2">
              <h4>Supervised Providers</h4>
              <div>
                {superviseeList?.map((item) => (
                  <div className="wrapper">
                    <span className="label">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="rating-area">
        <h4>Reviews</h4>
        <div className="reviews-rating-wrapper">
          <div className="rating">
            {_.get(selectedMyProviders, 'rating', 0)}
          </div>
          <div className="reviews">
            <div>
              <Rate disabled value={_.get(selectedMyProviders, 'rating', 0)} />
            </div>
            <span className="span">
              {_.get(selectedMyProviders, 'ratingCount', 0)}
              &nbsp;Reviews
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function TickSign() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.875C5.51992 1.875 1.875 5.51992 1.875 10C1.875 14.4801 5.51992 18.125 10 18.125C14.4801 18.125 18.125 14.4801 18.125 10C18.125 5.51992 14.4801 1.875 10 1.875ZM14.2285 7.27695L8.97852 13.527C8.92093 13.5955 8.84927 13.651 8.76839 13.6894C8.68752 13.7279 8.59932 13.7486 8.50977 13.75H8.49922C8.41162 13.75 8.325 13.7315 8.24499 13.6959C8.16497 13.6602 8.09335 13.6081 8.03477 13.543L5.78477 11.043C5.72762 10.9824 5.68317 10.9109 5.65403 10.8329C5.62488 10.7549 5.61162 10.6718 5.61504 10.5886C5.61846 10.5054 5.63848 10.4237 5.67392 10.3483C5.70936 10.2729 5.75952 10.2054 5.82143 10.1496C5.88335 10.0939 5.95578 10.0511 6.03447 10.0238C6.11315 9.99648 6.19651 9.98516 6.27963 9.99051C6.36276 9.99585 6.44398 10.0178 6.51851 10.0549C6.59305 10.0921 6.6594 10.1438 6.71367 10.207L8.48281 12.1727L13.2715 6.47305C13.3789 6.34886 13.5309 6.27193 13.6945 6.25889C13.8582 6.24584 14.0205 6.29774 14.1462 6.40335C14.2719 6.50896 14.351 6.6598 14.3664 6.82327C14.3818 6.98675 14.3323 7.14971 14.2285 7.27695Z" fill="#148051" />
    </svg>
  );
}
