import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_prmDashboard.scss';

function MenuItem({ isActive, title, counter }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''}`}>
      <span>{title}</span>
      {counter && <div className="counter">{`${counter >= 10 ? '9+' : counter}`}</div>}
    </div>
  );
}
function PrmDashboard() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  const { alerts } = useSelector((s) => s.alerts);

  return (
    <div className="dashBoardWrapper">
      <NavTabs>
        <NavTabs.Menu className="subNavMenu">
          {access?.profileSetup && (
            <NavTabs.MenuItems isExactPath to={URLS.PROFILE_SETUP_URL}>
              <MenuItem title="Profile Setup" />
            </NavTabs.MenuItems>
          )}
          {access?.myProviders && (
            <NavTabs.MenuItems isExactPath to={URLS.MY_PROVIDERS_URL}>
              <MenuItem title="My Providers" counter={alerts.docs.filter((a) => !a.alertText.toLowerCase().includes('complaint received')).length || ''} />
            </NavTabs.MenuItems>
          )}
          {access?.complaints && (
            <NavTabs.MenuItems isExactPath to={URLS.COMPLAINTS_URL}>
              <MenuItem title="Complaints" counter={alerts.docs.filter((a) => a.alertText.toLowerCase().includes('complaint received')).length || ''} />
            </NavTabs.MenuItems>
          )}

          {/* <NavTabs.MenuItems isExactPath to={URLS.PRM_DASHBOARD_STATS_URL}>
            <MenuItem title="PRM Dashboard" />
          </NavTabs.MenuItems>  */}
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default PrmDashboard;
