import { createSlice } from '@reduxjs/toolkit';
import {
  createProceduresSetup,
  updateProceduresSetup,
  listProceduresSetup,
  getProceduresSetup,
  deleteProcedureSetup,
} from './proceduresSetupActions';

const INITIAL_STATE = {
  isLoading: false,
  proceduresSetups: { docs: [] },
  selectedProcedureSetup: {},
};

export const proceduresSetupSlice = createSlice({
  name: 'proceduresSetupSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listProceduresSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listProceduresSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listProceduresSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.proceduresSetups = action.payload;
    });
    builder.addCase(getProceduresSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProceduresSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getProceduresSetup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedProcedureSetup = action.payload;
    });
    builder.addCase(createProceduresSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createProceduresSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createProceduresSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProceduresSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProceduresSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProceduresSetup.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProcedureSetup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProcedureSetup.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteProcedureSetup.fulfilled, (state) => {
      state.isLoading = false;
    });

    return builder;
  },
});

export const {
  clearData,
} = proceduresSetupSlice.actions;
