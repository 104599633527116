/* eslint-disable max-len */
import { Checkbox, Form } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateMyProviders } from 'redux-store/myProviders/myProvidersActions';
import Button from 'shared/components/button/Button';
import CustomDatePicker from 'shared/components/datePicker/DatePicker';
import DropDown from 'shared/components/dropdown/DropDown';
import { PROVIDER_STATUS } from 'shared/constants/global';
import { emptyFieldValidator } from 'shared/utils/validators';

export default function CheckLists({ onCancel }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { selectedMyProviders } = useSelector((state) => state.myProviders);
  const { user } = useSelector((state) => state.auth);

  const [form] = Form.useForm();
  const [, forceUpdate] = useState(0);
  const updateProvider = (vals) => {
    dispatch(updateMyProviders({ id, params: vals }));
  };
  const setVerifiedBy = (key) => () => {
    form.setFieldValue(key, user.id);
  };
  useEffect(() => {
    if (!_.isEmpty(selectedMyProviders)) {
      form.setFieldsValue({
        ...selectedMyProviders,
        verificationChecklist: Object.entries(selectedMyProviders.verificationChecklist).reduce((acc, [k, v]) => {
          acc[k] = { ...v, verifiedById: v?.verifiedById?.id };
          return acc;
        }, {}),
      });
      forceUpdate((v) => !v);
    }
  }, [selectedMyProviders]);

  return (
    <Form
      form={form}
      onFinish={updateProvider}
      // eslint-disable-next-line no-unused-vars
      onValuesChange={(vals) => {
        console.log(vals);
        forceUpdate((v) => !v);
      }}
    >
      <div className="provider-pop-up-container__checklist">
        <div className="d-grid">
          <span className="span">Task</span>
          <span className="span ml-25">Manual Date of Completion</span>
        </div>

        {/* <Form.Item noStyle name={['providerStartDate']} valuePropName="checked">
          <span /> s
        </Form.Item> */}
        <div className="scroll-bar">
          <div className="d-grid" style={{ gridTemplateColumns: '1fr auto auto' }}>
            <Form.Item name={['verificationChecklist', 'backgroundCheck', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'backgroundCheck', 'verifiedById'])}>
                <span className="text">
                  iProspect Background Check
                </span>
              </VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'backgroundCheck', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'backgroundCheck', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.backgroundCheck.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ marginLeft: 35 }}>
            <Form.Item
              rules={[
                {
                  validator: (e, v) => {
                    if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !v) {
                      return Promise.reject(e);
                    }
                    return Promise.resolve();
                  },
                  message: 'This field is required',
                },
              ]}
              name={['backgroundCheck', 'passedCountyCriminalByPrm']}
              valuePropName="checked"
            >
              <VerifiedCheckBox>
                <span className="text">County Criminal</span>
              </VerifiedCheckBox>
            </Form.Item>
            <Form.Item
              getValueProps={(i) => ({ value: i && moment(i) })}
              name={['backgroundCheck', 'passedCountyCriminalByPrmUpdatedDate']}
              rules={[{
                validator: (x, val) => {
                  if (form.getFieldValue(['backgroundCheck', 'passedCountyCriminalByPrm']) && !val) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'This field is required',
              }]}
            >
              <CustomDatePicker placeholder="Select Date" />
            </Form.Item>
            {_.get(selectedMyProviders, 'backgroundCheck.passedCountyCriminalByPrm') && <TickSign />}
          </div>

          <div className="d-grid" style={{ marginLeft: 35 }}>
            <Form.Item
              rules={[
                {
                  validator: (e, v) => {
                    if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !v) {
                      return Promise.reject(e);
                    }
                    return Promise.resolve();
                  },
                  message: 'This field is required',
                },
              ]}
              name={['backgroundCheck', 'passedNationalCriminalSexOffenderByPrm']}
              valuePropName="checked"
            >
              <VerifiedCheckBox>
                <span className="text">National Criminal + Sex Offender</span>
              </VerifiedCheckBox>
            </Form.Item>
            <Form.Item
              getValueProps={(i) => ({ value: i && moment(i) })}
              name={['backgroundCheck', 'passedNationalCriminalSexOffenderByPrmUpdatedDate']}
              rules={[{
                validator: (x, val) => {
                  if (form.getFieldValue(['backgroundCheck', 'passedNationalCriminalSexOffenderByPrm']) && !val) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'This field is required',
              }]}
            >
              <CustomDatePicker placeholder="Select Date" />
            </Form.Item>
            {_.get(selectedMyProviders, 'backgroundCheck.passedFacisLevelThreeByPrm') && <TickSign />}
          </div>

          <div className="d-grid" style={{ marginLeft: 35 }}>
            <Form.Item
              rules={[
                {
                  validator: (e, v) => {
                    if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !v) {
                      return Promise.reject(e);
                    }
                    return Promise.resolve();
                  },
                  message: 'This field is required',
                },
              ]}
              name={['backgroundCheck', 'passedFacisLevelThreeByPrm']}
              valuePropName="checked"
            >
              <VerifiedCheckBox>
                <span className="text">FACIS Level III</span>
              </VerifiedCheckBox>
            </Form.Item>
            <Form.Item
              getValueProps={(i) => ({ value: i && moment(i) })}
              name={['backgroundCheck', 'passedFacisLevelThreeByPrmUpdatedDate']}
              rules={[{
                validator: (x, val) => {
                  if (form.getFieldValue(['backgroundCheck', 'passedFacisLevelThreeByPrm']) && !val) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
                message: 'This field is required',
              }]}
            >
              <CustomDatePicker placeholder="Select Date" />
            </Form.Item>
            {_.get(selectedMyProviders, 'backgroundCheck.passedFacisLevelThreeByPrm') && <TickSign />}
          </div>

          <Form.List
            name={['backgroundCheck', 'medicalLicenses']}
            initialValue={selectedMyProviders.licensedStateCodes
              .map((s) => ({
                passedMedicalLicenseByPrm: false,
                passedMedicalLicenseByPrmUpdatedDate: '',
                stateCode: s,
              }))}
          >
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="d-grid" style={{ marginLeft: 35 }}>
                    <Form.Item
                      rules={[
                        {
                          validator: (e, v) => {
                            if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !v) {
                              return Promise.reject(e);
                            }
                            return Promise.resolve();
                          },
                          message: 'This field is required',
                        },
                      ]}
                      {...restField}
                      name={[name, 'passedMedicalLicenseByPrm']}
                      valuePropName="checked"
                    >
                      <VerifiedCheckBox>
                        <span className="text">
                          {form.getFieldValue(['backgroundCheck', 'medicalLicenses', name, 'stateCode'])}
                          {' '}
                          License
                        </span>
                      </VerifiedCheckBox>
                    </Form.Item>
                    {/* <Form.Item noStyle name={['backgroundCheck', 'medicalLicenses', name, 'stateCode']} valuePropName="checked">
                      <span />
                    </Form.Item> */}
                    <Form.Item
                      getValueProps={(i) => ({ value: i && moment(i) })}
                      {...restField}
                      name={[name, 'passedMedicalLicenseByPrmUpdatedDate']}
                      rules={[{
                        validator: (x, val) => {
                          if (form.getFieldValue(['backgroundCheck', 'medicalLicenses', name, 'passedMedicalLicenseByPrm']) && !val) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: 'This field is required',
                      }]}
                    >
                      <CustomDatePicker placeholder="Select Date" />
                    </Form.Item>
                    {form.getFieldValue(['backgroundCheck', 'medicalLicenses', name, 'passedMedicalLicenseByPrm']) && <TickSign />}
                  </div>
                ))}
              </>
            )}
          </Form.List>

          <Form.List
            name={['backgroundCheck', 'education']}
            initialValue={[
              !_.isEmpty(selectedMyProviders.undergraduateEducation) && {
                passedEducationByPrm: false,
                passedEducationByPrmUpdatedDate: '',
                universityName: selectedMyProviders.undergraduateEducation.universityName,
              },
              !_.isEmpty(selectedMyProviders.graduateEducation) && {
                passedEducationByPrm: false,
                passedEducationByPrmUpdatedDate: '',
                universityName: selectedMyProviders.graduateEducation.universityName,
              },
            ].filter((k) => !_.isEmpty(k))}
          >
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="d-grid" style={{ marginLeft: 35 }}>
                    <Form.Item
                      rules={[
                        {
                          validator: (e, v) => {
                            if (form.getFieldValue(['verificationChecklist', 'backgroundCheck', 'verified']) && !v) {
                              return Promise.reject(e);
                            }
                            return Promise.resolve();
                          },
                          message: 'This field is required',
                        },
                      ]}
                      {...restField}
                      name={[name, 'passedEducationByPrm']}
                      valuePropName="checked"
                    >
                      <VerifiedCheckBox>
                        <span className="text">
                          {form.getFieldValue(['backgroundCheck', 'education', name, 'universityName'])}
                          {' '}
                          University
                        </span>
                      </VerifiedCheckBox>
                    </Form.Item>
                    {/* <Form.Item noStyle name={['backgroundCheck', 'medicalLicenses', name, 'stateCode']} valuePropName="checked">
                      <span />
                    </Form.Item> */}
                    <Form.Item
                      getValueProps={(i) => ({ value: i && moment(i) })}
                      {...restField}
                      name={[name, 'passedEducationByPrmUpdatedDate']}
                      rules={[{
                        validator: (x, val) => {
                          if (form.getFieldValue(['backgroundCheck', 'education', name, 'passedEducationByPrm']) && !val) {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                        message: 'This field is required',
                      }]}
                    >
                      <CustomDatePicker placeholder="Select Date" />
                    </Form.Item>
                    {form.getFieldValue(['backgroundCheck', 'education', name, 'passedEducationByPrm']) && <TickSign />}
                  </div>
                ))}
              </>
            )}
          </Form.List>

          {/* <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'stateLicenses', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'stateLicenses', 'verifiedById'])}><span className="text">State Licensure</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'stateLicenses', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'stateLicenses', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'stateLicenses', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'stateLicenses', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.stateLicenses.verified') && <TickSign />}
            </div>
          </div> */}

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'boardCertification', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'boardCertification', 'verifiedById'])}><span className="text">Board Certification</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'boardCertification', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'boardCertification', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'boardCertification', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'boardCertification', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.boardCertification.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'supervisorCheck', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'supervisorCheck', 'verifiedById'])}><span className="text">Supervisor Attestation Signed/Supervisor Obtained</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'supervisorCheck', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'supervisorCheck', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'supervisorCheck', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'supervisorCheck', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.supervisorCheck.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'profileComplete', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'profileComplete', 'verifiedById'])}><span className="text">Complete Provider Profile</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'profileComplete', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'profileComplete', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'profileComplete', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'profileComplete', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.profileComplete.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'meetAndGreet', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'meetAndGreet', 'verifiedById'])}><span className="text">Person to Person Meet & Greet</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'meetAndGreet', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'meetAndGreet', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'meetAndGreet', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'meetAndGreet', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.meetAndGreet.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'availabilityCreated', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'availabilityCreated', 'verifiedById'])}><span className="text">Calendar Set Up</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'availabilityCreated', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'availabilityCreated', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'availabilityCreated', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'availabilityCreated', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.availabilityCreated.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'hipaaTraining', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'hipaaTraining', 'verifiedById'])}><span className="text">HIPAA Training</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'hipaaTraining', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'hipaaTraining', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'hipaaTraining', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'hipaaTraining', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.hipaaTraining.verified') && <TickSign />}
            </div>
          </div>

          <div className="d-grid" style={{ gridTemplateColumns: '1fr 0fr 1fr' }}>
            <Form.Item name={['verificationChecklist', 'henryScheinOrderSetup', 'verified']} valuePropName="checked">
              <VerifiedCheckBox onChangeAfter={setVerifiedBy(['verificationChecklist', 'henryScheinOrderSetup', 'verifiedById'])}><span className="text">Supplies Order Set-up Completion for Henry Schein</span></VerifiedCheckBox>
            </Form.Item>
            <Form.Item noStyle name={['verificationChecklist', 'henryScheinOrderSetup', 'verifiedById']} valuePropName="checked">
              <span />
            </Form.Item>
            <div className="d-flex">
              {form.getFieldValue(['verificationChecklist', 'henryScheinOrderSetup', 'verified']) && (
                <Form.Item
                  name={['verificationChecklist', 'henryScheinOrderSetup', 'timestamp']}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                  rules={[{
                    validator: (x, val) => {
                      if (form.getFieldValue(['verificationChecklist', 'henryScheinOrderSetup', 'verified']) && !val) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                    message: 'This field is required',
                  }]}
                >
                  <CustomDatePicker placeholder="Select Date" />
                </Form.Item>
              )}
              {_.get(selectedMyProviders, 'verificationChecklist.henryScheinOrderSetup.verified') && <TickSign />}
            </div>
          </div>

        </div>
        <div className="provider-pop-up-container__checklist--footer">
          {!Object.values(form.getFieldValue(['verificationChecklist']) || {})
            .find((x) => x.verified === false || x.verified === undefined)
            && (
              <div>
                <Form.Item
                  name="manualVerification"
                  valuePropName="checked"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <Checkbox>
                    <span className="text">I consent that the above checklist was verified manually.</span>
                  </Checkbox>
                </Form.Item>
                {/* <Form.Item
                  noStyle
                  name="manualVerificationDate"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <HiddenInput finalvalue={moment()} />
                </Form.Item>
                <Form.Item
                  noStyle
                  name="manualVerificationBy"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <HiddenInput finalvalue={profile.id} />
                </Form.Item> */}
              </div>
            )}
        </div>

        <div className="provider-pop-up-container__checklist--footer">
          <label htmlFor="providerStatus">
            Provider Status
            <Form.Item
              name="providerStatus"
            // rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
            >
              <DropDown
                placeholder="Provider Status"
                className="resolution-dropdown"
                isOptionsObj
                options={
                  PROVIDER_STATUS.filter((v) => (
                    !v?.value?.toLowerCase().includes('active')
                    || (
                      v?.value?.toLowerCase().includes('active')
                      && !Object.values(form.getFieldValue(['verificationChecklist']) || {})
                        .find((x) => x.verified === false || x.verified === undefined)
                    )
                  ))
                }
              />
            </Form.Item>
          </label>
          <div>
            <Button variant={Button.variant.outlined} onClick={onCancel}>Cancel</Button>
            <Button
              disabled={
                !Object.values(form.getFieldValue(['verificationChecklist']) || {})
                  .find((x) => x.verified === true)
              }
              variant={Button.variant.filled}
              onClick={form.submit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Form>

  );
}

function VerifiedCheckBox({
  children,
  onChangeAfter,
  onChange,
  ...props
}) {
  const onCheck = (v) => {
    onChange(v);
    if (onChangeAfter) {
      onChangeAfter(v);
    }
  };
  return (
    <Checkbox {...props} onChange={onCheck}>{children}</Checkbox>
  );
}
function TickSign() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="#F16924" />
    </svg>
  );
}
