import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { listComplaints } from 'redux-store/complaints/complaintsActions';
import { URLS } from 'routes';
import DropDown from 'shared/components/dropdown/DropDown';
// import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { ComplaintsGridColumns } from 'utils/grid/columns';
import { listProviderAlerts, updateMultipleAlerts } from 'redux-store/alerts/alertsActions';

export default function ComplaintsList() {
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [filterBy, setFilterBy] = useState('where[or][0][resolution.resolutionDecision][like]');
  const { complaints } = useSelector((state) => state.complaints);
  const myId = useSelector((state) => state?.auth?.profile?.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alerts } = useSelector((s) => s.alerts);

  const ProviderNameClick = (item) => {
    navigate(`${URLS.COMPLAINTS_URL}/${item.id}`);
  };

  const onSearch = (v) => {
    setParams({
      limit: 10,
      page: 1,
      [filterBy]: v,
    });
  };

  const updateComplaintAlerts = () => {
    if (alerts.docs.filter((a) => a.alertText.toLowerCase().includes('complaint received'))?.length > 0) {
      dispatch(
        updateMultipleAlerts({
          params: { isRead: true },
          query: alerts.docs.filter((a) => a.alertText.toLowerCase().includes('complaint received'))?.reduce((acc, o, i) => {
            acc[`where[id][in][${i}]`] = o.id;
            return acc;
          }, {}),
        }),
      ).then(() => {
        dispatch(listProviderAlerts({ 'where[isRead][equals]': false, limit: 0 }));
      });
    }
  };

  useEffect(() => {
    if (myId) {
      dispatch(listComplaints(_.omitBy(params, _.isEmpty)));
      updateComplaintAlerts();
    }
  }, [params, myId]);

  return (
    <div className="complaint-container">
      <div className="complaint-container__header">
        <SearchBox onChange={onSearch} placeholder="Search by .." />
        <DropDown
          placeholder="Filter By"
          isOptionsObj
          className="provider-list-dropdown"
          value={filterBy}
          onChange={setFilterBy}
          options={[
            {
              value: 'where[or][0][resolution.resolutionDecision][like]',
              label: 'Status',
            },
            {
              value: 'where[or][0][id][like]',
              label: 'Complaint ID',
            },
            {
              value: 'where[or][0][providerId.firstName][like]',
              label: 'Provider First Name',
            },
            {
              value: 'where[or][0][providerId.middleName][like]',
              label: 'Provider Middle Name',
            },
            {
              value: 'where[or][0][providerId.lastName][like]',
              label: 'Provider Last Name',
            },
          ]}
        />
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={ComplaintsGridColumns(
            { ProviderNameClick: (identifier, item) => ProviderNameClick(identifier, item) },
          )}
          data={complaints.docs}
          pagination={{
            total: complaints.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}
