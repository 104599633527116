/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { createMedia } from 'redux-store/media/mediaActions';
import axios from 'shared/axios/axios';
import { PROCEDURE_IMAGE, PROCEDURE_PDF, PROCEDURE_VIDEO } from 'shared/constants/global';

import { PROCEDURES_SETUPS_API_URL } from './proceduresSetupUrls';

export const listProceduresSetup = createAsyncThunk(
  'proceduresSetupSlice/listProceduresSetup',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROCEDURES_SETUPS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getProceduresSetup = createAsyncThunk(
  'proceduresSetupSlice/getProceduresSetup',
  async (id) => {
    return axios.get(PROCEDURES_SETUPS_API_URL(id)).then(({ data }) => data);
  },
);

export const createProceduresSetup = createAsyncThunk(
  'proceduresSetupSlice/createProceduresSetup',
  async (params) => {
    const img = await createMedia(params.procedureImage, PROCEDURE_IMAGE);
    let pdf = null;
    let video = null;
    if (_.get(params, 'procedureVideo', null)) {
      video = await createMedia(params.procedureImage, PROCEDURE_VIDEO);
    }
    if (_.get(params, 'procedurePdf', null)) {
      pdf = await createMedia(params.procedureImage, PROCEDURE_PDF);
    }
    return axios.post(
      PROCEDURES_SETUPS_API_URL(),
      {
        ...params,
        procedureImage: _.get(img, 'doc.id'),
        procedureVideo: _.get(video, 'doc.id'),
        procedurePdf: _.get(pdf, 'doc.id'),
      },
    ).then(({ data }) => {
      toast.success('Procedure has been created');
      return data;
    });
  },
);

export const updateProceduresSetup = createAsyncThunk(
  'proceduresSetupSlice/updateProceduresSetup',
  async ({ id, params }, { dispatch }) => {
    let videoID = _.get(params, 'procedureVideo', null);
    let imgID = _.get(params, 'procedureImage', null);
    let pdfID = _.get(params, 'procedurePdf', null);

    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.procedureImage, PROCEDURE_IMAGE);
      imgID = img.doc.id;
    }
    if (videoID && typeof (videoID) === 'object') {
      const video = await createMedia(params.procedureVideo, PROCEDURE_VIDEO);
      videoID = video.doc.id;
    }
    if (pdfID && typeof (pdfID) === 'object') {
      const pdf = await createMedia(params.procedurePdf, PROCEDURE_PDF);
      pdfID = pdf.doc.id;
    }
    return axios.patch(
      PROCEDURES_SETUPS_API_URL(id),
      {
        ...params,
        procedureImage: imgID,
        procedureVideo: videoID,
        procedurePdf: pdfID,
      },
    ).then(() => {
      toast.success('Procedure has been updated');
      return dispatch(getProceduresSetup(id));
    });
  },
);

export const deleteProcedureSetup = createAsyncThunk(
  'categorySetupSlice/deleteProcedureSetup',
  async (id, { dispatch }) => {
    return axios.patch(PROCEDURES_SETUPS_API_URL(id), { deletedInfoField: { isDeleted: true } })
      .then(() => {
        toast.success('Procedure has been deleted');
        return dispatch(listProceduresSetup());
      });
  },
);
