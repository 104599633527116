import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_procedureManagement.scss';

function MenuItem({ isActive, title }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''}`}>
      <span>{title}</span>
    </div>
  );
}
function ProcedureManagement() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);

  return (
    <div className="dashBoardWrapper">
      <NavTabs>
        <NavTabs.Menu className="subNavMenu">
          {access?.categorySetup && (
            <NavTabs.MenuItems to={URLS.CATEGORY_SETUP_URL}>
              <MenuItem title="Category Setup" />
            </NavTabs.MenuItems>
          )}
          {access?.suppliesSetup && (
            <NavTabs.MenuItems to={URLS.SUPPLIES_SETUP_URL}>
              <MenuItem title="Supplies" />
            </NavTabs.MenuItems>
          )}
          {access?.procedureSetup && (
            <NavTabs.MenuItems to={URLS.PROCEDURE_SETUP_URL}>
              <MenuItem title="Procedure Setup" />
            </NavTabs.MenuItems>
          )}
          {access?.labs && (
            <NavTabs.MenuItems to={URLS.AT_HOME_LABS_URL}>
              <MenuItem title="At Home Labs Set Up" />
            </NavTabs.MenuItems>
          )}
          <NavTabs.MenuItems to={URLS.BIOMARKER_URL}>
            <MenuItem title="Biomarker Set Up" />
          </NavTabs.MenuItems>
          <NavTabs.MenuItems to={URLS.MEDICATION_SETS_URL}>
            <MenuItem title="Medication Sets Set Up" />
          </NavTabs.MenuItems>
          <NavTabs.MenuItems to={URLS.QUICKLINKS_URL}>
            <MenuItem title="Quick Links Set Up" />
          </NavTabs.MenuItems>
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default ProcedureManagement;
