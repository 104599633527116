import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { initialiseApp } from 'redux-store/auth/authActions';
import { setLoggedIn } from 'redux-store/auth/authSlice';
import { URLS } from 'routes';
import { doesSessionExist } from 'supertokens-web-js/recipe/session';

function VerifyLoggedIn({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    doesSessionExist()
      .then((isLoggedIn) => {
        const completePath = `${location.pathname}${location.search}`;
        if (isLoggedIn) {
          dispatch(initialiseApp());
          dispatch(setLoggedIn(isLoggedIn));
          if (location.pathname.includes('auth')) {
            return navigate(URLS.DASHBOARD_URL);
          }
          return null;
        }
        if (!location.pathname.includes('auth')) {
          return navigate(URLS.LOGIN_URL);
        }
        return navigate(completePath);
      });
  }, []);

  return children;
}
export default VerifyLoggedIn;
