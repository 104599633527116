/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import './_schedule.scss';

function ScheduleUI() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);
  const [weekAvailability, setWeekAvailability] = useState({});
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    if (selectedMyProviders.availability) {
      const groupedKeys = selectedMyProviders.availability?.reduce(
        (group, item) => {
          if (!group[item.dayOfWeek]) {
            group[item.dayOfWeek] = [];
          }
          group[item.dayOfWeek].push(item);
          return group;
        },
        {},
      );
      setWeekAvailability(groupedKeys);
    }
  }, [selectedMyProviders]);
  return (
    <div className="scheduleContainer">
      {Object.keys(weekAvailability)?.map((item, index) => (
        <div key={index} className="availability">
          <div className="availability__item">
            <span>{weekday[item] || ''}</span>
            <div>
              {weekAvailability[item]?.map((subItem, jindex) => (
                <div
                  key={`${index}_${jindex}`}
                  className="availability__item__time"
                >
                  <span>
                    {moment(
                      `${moment().format('YYYY-MM-DD ')}${
                        subItem.hourRangeStart
                      }:${subItem.minuteRangeStart} `,
                    ).format('hh:mm A')}
                  </span>
                  <span>&nbsp;-&nbsp;</span>
                  <span>
                    {moment(
                      `${moment().format('YYYY-MM-DD ')}${
                        subItem.hourRangeEnd
                      }:${subItem.minuteRangeEnd}`,
                    ).format('hh:mm A')}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default ScheduleUI;
