import React, { useEffect, useState } from 'react';
import { ProfileImage } from 'shared/components/profileImage/ProfileImage';
import { AlertGridColumns } from 'utils/grid/columns';
import Grid from 'shared/components/grid/Grid';
import './_alert.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProviderAlerts,
  updateAlerts,
} from 'redux-store/alerts/alertsActions';

function AlertsList({ to }) {
  const dispatch = useDispatch();
  const { providerAlerts } = useSelector((state) => state.alerts);
  const INITIAL_PARAMS = { limit: 10, page: 1, searchText: '' };
  const [params, setParams] = useState(INITIAL_PARAMS);

  const getAllAlerts = (id) => {
    if (id) {
      dispatch(
        getProviderAlerts({ ...params, 'where[provider][equals]': id, sort: 'isRead' }),
      );
    }
  };

  useEffect(() => {
    getAllAlerts(to?.id);
  }, [params, to]);

  const updateAlertRead = (record) => {
    if (record?.length > 0 && record[0].isRead === false) {
      dispatch(
        updateAlerts({ id: record[0]?.id, params: { isRead: true } }),
      ).then((payload) => {
        if (!payload.error) {
          getAllAlerts(to?.id);
        }
      });
    }
  };

  return (
    <div className="chatContainer">
      <div className="chat-header">
        <ProfileImage user={to} />
      </div>
      <Grid
        columns={AlertGridColumns(() => {})}
        data={providerAlerts?.docs}
        pagination={{
          total: providerAlerts.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
        onRow={(record) => ({
          onClick: () => {
            updateAlertRead([record]);
          },
        })}
        rowClassName={(record) => (!record?.isRead ? 'table-row-unread' : '')}
      />
    </div>
  );
}

export default AlertsList;
