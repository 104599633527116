/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import chatTick from '../../../../assets/imgs/chatTick.png';

export default function WorkHistory() {
  const { selectedMyProviders } = useSelector((state) => state.myProviders);

  return (
    <div className="provider-pop-up-container__work">
      {_.get(selectedMyProviders, 'workHistories', []).map((h) => (
        <div>
          <div className="history">
            <div className="key">{h.employerName}</div>
            <div className="value">
              {h.startDate && moment(h.startDate).format('MM/DD/YYYY')}
              {' '}
              –
              {' '}
              {h.endDate && moment(h.endDate).format('MM/DD/YYYY')}
            </div>
            <div className="value m-width">
              {h?.employerAddress?.addressName || ''}
              {h?.employerAddress?.addressLine1}
              {', '}
              <br />
              {h?.employerAddress?.addressLine2}
              {', '}
              <br />
              {h?.employerAddress?.city}
              {', '}
              {h?.employerAddress?.stateCode}
              {' '}
              {h?.employerAddress?.postalCode}
              <br />
              United States
            </div>
          </div>
          <div className="border" />
          {!Object.values(_.get(selectedMyProviders, 'verificationChecklist')).find((o) => !o.verified) && (
            <p style={{ alignItems: 'center', display: 'flex' }}>
              <img src={chatTick} alt="" width={16} height={16} />
              Verified By Revdoc
            </p>
          )}
        </div>
      ))}
    </div>
  );
}
