import React, { useState } from 'react';
import './_addMedicationSet.scss';
import InputField from 'shared/components/inputField/InputField';
import { Form, Popconfirm, Space } from 'antd';
import { blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import { TrashIcon } from 'utils/grid/columns';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import { ReactComponent as AddProduct } from '../../../../../../assets/svgs/add-product.svg';
import Product from './Product';
import AddNewProductList from './AddNewProductList';

export default function MedicationSupply({
  value,
  medicationSet,
  medicationSupplyObject,
  removeSupply,
  onSelectProduct,
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <div className="medication-supply-products-container">
      <Space size="middle" className="medication-action-btn">
        <Popconfirm
          title="Are you sure?"
          description="Do you want to delete this medication/supply?"
          okText="Yes"
          cancelText="No"
          onConfirm={removeSupply}
        >
          <a>
            <TrashIcon />
          </a>
        </Popconfirm>
      </Space>
      <Form.Item
        name={[medicationSupplyObject.name, 'medicationName']}
        className="formfield"
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        <InputField
          label=" Medication/Supply Name *"
          placeholder=" Medication/Supply Name"
          maxLength={250}
        />
      </Form.Item>
      <Form.Item
        name={[medicationSupplyObject.name, 'defaultEssentialCopyGuidance']}
        className="formfield"
      >
        <InputField
          label=" Default Essential Copy Guidance"
          placeholder=" Default Essential Copy Guidance"
          maxLength={250}
        />
      </Form.Item>
      <Form.List
        name={[medicationSupplyObject.name, 'supplyGroup']}
        rules={[
          {
            validator: emptyFieldValidator,
            message: 'This field is required',
          },
          blackListedChars,
        ]}
      >
        {(fields, { remove }) => (
          <>
            {fields.map((productObject, index) => (
              <Product
                {...fields}
                productObject={productObject}
                key={`PROD_${index}`}
                removeProduct={() => remove(productObject.name)}
              />
            ))}
            <span
              className="add-new-product-btn"
              role="presentation"
              onClick={() => setDialogOpen(true)}
            >
              <AddProduct />
            </span>
            <DialogPopup
              className="add-bio-marker-dialog"
              open={dialogOpen}
              title="Select Products"
              handleOk={() => {}}
              handleCancel={() => setDialogOpen(false)}
              cancelBtn=""
              showActionButton={false}
              saveBtn=""
            >
              <AddNewProductList
                value={value}
                fields={fields}
                ms={medicationSet}
                onChange={(val) => {
                  setDialogOpen(false);
                  onSelectProduct(val);
                  // val.forEach((o) => {
                  //   add({
                  //     productId: { id: o.id, productId: o.id },
                  //     id: o.id,
                  //     productName: o.productName,
                  //     defaultProduct: false,
                  //   });
                  // });
                }}
              />
            </DialogPopup>
          </>
        )}
      </Form.List>
    </div>
  );
}
