import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPrivacyPolicyLive, listPrivacyPolicy } from 'redux-store/privacyPolicy/privacyPolicyActions';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import Grid from 'shared/components/grid/Grid';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { PrivacyPolicyGridColumns } from 'utils/grid/columns';
import { RichTextEditor } from 'shared/components/richTextEditor/RichTextEditor';

function PrivacyPolicy() {
  const searchKey = 'where[version.versionName][like]';
  const INITIAL_PARAMS = {
    limit: 10,
    page: 1,
    [searchKey]: '',
  };
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [viewRecord, setViewRecord] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { privacyPolicies, livePrivacyPolicy } = useSelector((state) => state.privacyPolicies);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addNewBtnClick = () => {
    navigate(`${URLS.PRIVACY_POLICY_URL}/new`);
  };
  const editClick = (id) => {
    navigate(`${URLS.PRIVACY_POLICY_URL}/${id}`);
  };

  const onSearch = (v) => {
    setParams({
      limit: 10,
      page: 1,
      [searchKey]: v,
    });
  };

  const ActionClickHanlder = (identifier, item) => {
    if (identifier === 'edit') {
      editClick(item?.id);
    } else if (identifier === 'view') {
      setViewRecord(item);
    }
  };

  useEffect(() => {
    dispatch(listPrivacyPolicy(params));
    dispatch(getPrivacyPolicyLive());
  }, [params]);

  return (
    <div className="revdoc-setup-container">
      <div className="revdoc-setup-container__header">
        <SearchBox placeholder="Search Privacy Policy " onChange={onSearch} />
        {/* <DropDown
          placeholder="Filter By"
          isOptionsObj
          className="provider-list-dropdown"
          options={[]}
        /> */}
        <Button variant={Button.variant.filled} onClick={addNewBtnClick}>
          + Add New Privacy Policy
        </Button>
      </div>
      <h3>Live</h3>
      <Grid
        columns={
          PrivacyPolicyGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )
        }
        data={_.isEmpty(livePrivacyPolicy) ? [] : [{ version: livePrivacyPolicy }]}
        pagination={false}
      />
      <h3>Versions</h3>
      <Grid
        columns={
          PrivacyPolicyGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )
        }
        data={privacyPolicies.docs}
        pagination={{
          total: privacyPolicies.totalDocs,
          current: params.page,
          defaultPageSize: 10,
          onChange: (page, pageSize) => {
            setParams({ ...params, page, limit: pageSize });
          },
        }}
      />
      {!_.isEmpty(viewRecord) && (
        <DialogPopup
          className="provider-popup"
          open={!_.isEmpty(viewRecord)}
          title="View Content"
          handleCancel={() => setViewRecord(null)}
          cancelBtn="Close"

        >
          <div>
            <RichTextEditor preview value={viewRecord?.version?.privacyText} />
          </div>
        </DialogPopup>
      )}
    </div>
  );
}

export default PrivacyPolicy;
