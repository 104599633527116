import _ from 'lodash';
import React from 'react';
import chatTick from '../../../assets/imgs/chatTick.png';

export function ProfileImage({ user }) {
  return (
    <div className="img-text-wrapper">
      <div className="img">
        {!Object.entries(user?.verificationChecklist || {})
          .find(([, checklist]) => !checklist.verified) && (
            <div className="chat-tick"><img src={chatTick} alt="" width={16} height={16} /></div>)}
        <img src={user?.biography?.providerPhoto?.url} alt="" width={40} height={40} />
      </div>
      <span className="text">
        {`${_.get(user, 'firstName', '')} ${_.get(user, 'middleName', '')} ${_.get(user, 'lastName', '')}`}
        {_.get(user, 'providerType.providerTypeName', '') ? `, ${_.get(user, 'providerType.providerTypeName', '')}` : ''}
      </span>
    </div>
  );
}
